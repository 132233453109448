<script setup lang="ts">
import { getHashFromString } from '@mop/shared/utils/util';
import type { ConcreteComponent } from 'vue';
import UiCmsAnchor from '@mop/ui/components/cms/UiCmsAnchor.vue';
import UiCmsBenefit from '@mop/ui/components/cms/UiCmsBenefit.vue';
import UiCmsCardCarousel from '@mop/ui/components/cms/UiCmsCardCarousel.vue';
import UiCmsCountdown from '@mop/ui/components/cms/UiCmsCountdown.vue';
import UiCmsCta from '@mop/ui/components/cms/UiCmsCta.vue';
import UiCmsDoubleCard from '@mop/ui/components/cms/UiCmsDoubleCard.vue';
import UiCmsFaqImageTiles from '@mop/ui/components/cms/UiCmsFaqImageTiles.vue';
import UiCmsFullWidthBanner from '@mop/ui/components/cms/UiCmsFullWidthBanner.vue';
import UiCmsGenderSwitch from '@mop/ui/components/cms/UiCmsGenderSwitch.vue';
import UiCmsHeadline from '@mop/ui/components/cms/UiCmsHeadline.vue';
import UiCmsHtml from '@mop/ui/components/cms/UiCmsHtml.vue';
import UiCmsImageText from '@mop/ui/components/cms/UiCmsImageText.vue';
import UiCmsRichText from '@mop/ui/components/cms/UiCmsRichText.vue';
import UiCmsSeoTeaser from '@mop/ui/components/cms/UiCmsSeoTeaser.vue';
import UiCmsSeparator from '@mop/ui/components/cms/UiCmsSeparator.vue';
import UiCmsSizeTable from '@mop/ui/components/cms/UiCmsSizeTable.vue';
import UiCmsSlider from '@mop/ui/components/cms/UiCmsSlider.vue';
import UiCmsStaticNavigation from '@mop/ui/components/cms/UiCmsStaticNavigation.vue';
import UiCmsTable from '@mop/ui/components/cms/UiCmsTable.vue';
import UiCmsTextCarousel from '@mop/ui/components/cms/UiCmsTextCarousel.vue';
import UiCmsTimeline from '@mop/ui/components/cms/UiCmsTimeline.vue';
import UiCmsTripleCard from '@mop/ui/components/cms/UiCmsTripleCard.vue';
import UiCmsTripleTeaser from '@mop/ui/components/cms/UiCmsTripleTeaser.vue';
import UiCmsVideoVimeo from '@mop/ui/components/cms/UiCmsVideoVimeo.vue';
import UiCmsElementNotFound from '@mop/ui/components/cms/UiCmsElementNotFound.vue';

import CompanyBlogNewsTripleCard from '@/components/mop/cms/company/BlogNewsTripleCard.vue';
import CompanyTopJobs from '@/components/mop/cms/company/TopJobs.vue';
import CompanyVacancies from '@/components/mop/cms/company/Vacancies.vue';
import CompanyAudio from '@/components/mop/cms/company/Audio.vue';
import type { CmsContentElementModel, CmsContentElementModelSlotItem, CmsVisibility } from '@/types/cms';

defineOptions({
  name: 'MopCmsContentElementsSlot',
});

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  elements: {
    type: Array as PropType<CmsContentElementModel[]>,
    required: true,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
  campaignName: {
    type: String,
    default: null,
  },
});

const { $storyblokLivePreview } = useNuxtApp();
const isStoryblokLivePreview = $storyblokLivePreview.isEnabled;
const forceLazyLoadFromIndex = 3;
const elementObservers: IntersectionObserver[] = [];

onUnmounted(() => {
  elementObservers.forEach((elementObserver) => {
    elementObserver.disconnect();
  });
});

const componentMapping = {
  // Global ui components
  Anchor: UiCmsAnchor,
  Benefit: UiCmsBenefit,
  CardCarousel: UiCmsCardCarousel,
  Countdown: UiCmsCountdown,
  Cta: UiCmsCta,
  DoubleCard: UiCmsDoubleCard,
  FaqImageTiles: UiCmsFaqImageTiles,
  FullWidthBanner: UiCmsFullWidthBanner,
  GenderSwitch: UiCmsGenderSwitch,
  Headline: UiCmsHeadline,
  Html: UiCmsHtml,
  ImageText: UiCmsImageText,
  RichText: UiCmsRichText,
  SeoTeaser: UiCmsSeoTeaser,
  Separator: UiCmsSeparator,
  SizeTable: UiCmsSizeTable,
  Slider: UiCmsSlider,
  StaticNavigation: UiCmsStaticNavigation,
  Table: UiCmsTable,
  TextCarousel: UiCmsTextCarousel,
  Timeline: UiCmsTimeline,
  TripleCard: UiCmsTripleCard,
  TripleTeaser: UiCmsTripleTeaser,
  VideoVimeo: UiCmsVideoVimeo,
  // Theme components
  CompanyBlogNewsTripleCard,
  CompanyTopJobs,
  CompanyVacancies,
  CompanyAudio,
};

function getComponent(element: CmsContentElementModel) {
  // @ts-ignore
  const component = componentMapping[element.getType()] || UiCmsElementNotFound;
  return component as ConcreteComponent | string;
}

function getComponentKey(element: CmsContentElementModel) {
  if (!isStoryblokLivePreview) {
    return element.getUid();
  }
  return getHashFromString(JSON.stringify(element.getData()));
}

function getComponentData(element: CmsContentElementModel) {
  return element.getData();
}

const elementsRef = computed(() => {
  return props.elements.reduce((elementList: CmsContentElementModelSlotItem[], element) => {
    const componentData = getComponentData(element);
    if (!componentData) {
      return elementList;
    }

    if (element.getType() === 'Slider') {
      componentData.slides?.forEach((slide: { isVisible: boolean; visibility: CmsVisibility }) => {
        slide.isVisible = true;
      });
    }

    componentData.isProductionBuild = isProductionBuild;

    const returnElement: CmsContentElementModelSlotItem = {
      ...element,
      componentKey: getComponentKey(element),
      componentData,
      componentName: getComponent(element),
      componentIsVisible: true,
    };

    elementList.push(returnElement);
    return elementList;
  }, []);
});
</script>

<template>
  <div :id="`cms-content-slot-${id}`" :class="['cms-content-slot', { 'cms-content-slot--no-padding': noPadding }]">
    <div
      v-for="(element, index) in elementsRef"
      :key="element.componentKey"
      :element-id="element.getUid()"
      :class="[
        'cms-content-slot__row',
        {
          'cms-content-slot__row--hidden': element.getVisibility() === constants.STORYBLOK.VISIBILITY_HIDDEN,
        },
      ]"
    >
      <span v-if="element.getCustomId()" :id="element.getCustomId()" class="cms-content-slot__anchor" />
      <component
        :is="element.componentName"
        :class="`element-count-${elements.length}`"
        :data="element.componentData"
        :component-count="elements.length"
        :lazy-load="lazyLoad || index >= forceLazyLoadFromIndex"
        :data-track-element="`${element.getType()}|${index + 1}`"
      />
      <div
        v-if="
          isStoryblokLivePreview &&
          element.getVisibility() &&
          element.getVisibility() !== constants.STORYBLOK.VISIBILITY_ALL
        "
        v-storyblok-editable="element.componentData"
        class="cms-content-slot__visibility"
      >
        <span class="cms-content-slot__visibility-icon"> ! </span>
        <span class="cms-content-slot__visibility-detail">
          {{ element.getVisibility() }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cms-content-slot--no-padding {
  &:deep(.ui-cms-padding) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cms-content-slot__row {
  position: relative;
  width: 100%;

  .wrapper__iframe-live-preview & {
    min-height: 20px;
  }
}

.cms-content-slot__anchor {
  display: inline-block;
  position: absolute;
  top: -$sticky-big-header-height;
}

.cms-content-slot__visibility-detail {
  display: inline-block;
}

.cms-content-slot__row--hidden > div:not(.cms-content-slot__visibility) {
  display: none;
}

.cms-content-slot__visibility {
  position: absolute;
  top: 0;
  left: 0;
  background: $signal-alert;
  color: $white;
  font-size: 9px;
  padding: 0 5px;

  @include hover {
    .cms-content-slot__visibility-icon {
      display: none;
    }
  }
}
</style>
